module.exports = {
  siteTitle: "KARTSCHÜÜR.ch",
  errorSiteTitle: "Uups, Seite nicht auffindbar",
  manifestName: "Kartschüür.ch",
  manifestShortName: "Kartschüür", // max 12 characters
  manifestStartUrl: "/",
  trackingId: "UA-156842257-1",
  manifestBackgroundColor: "#BA9358",
  manifestThemeColor: "#BA9358",
  manifestDisplay: "standalone",
  manifestIcon: "src/assets/img/favicon.png",
  pathPrefix: `/kartschuur.ch/`, // This path is subpath of your hosting https://domain/portfolio
  heading: "Intesso engineering gmbh",
  subHeading:
    "Wir entwerfen und entwickeln Individual Software und Web Lösungen. intesso setzt leidenschaftlich gerne Technologien mit offenen Standards ein, und ist begeistert von Open Source Integrationen.",

  // social
  socialLinks: [
    {
      icon: "fa-facebook-f",
      name: "Facebook",
      url: "https://www.facebook.com/kart.games",
    },
    {
      icon: "fa-instagram",
      name: "Instagram",
      url: "https://www.instagram.com/kart.games/",
    },
  ],
  email: "iris.neck@intesso.com",
  phone: "+41 79 745 78 09",
  address: "Schellingschüür, Bahnweg, 8447 Dachsen",
  news1: "WIR SIND umgezogen",
  news2: "",
  countdown: false,
  introTitleIndex: "",
  introSubtitleIndex: "",
  introTitleEvents: "WIR SIND umgezogen",
  introSubtitleEvents: "",
}
