import React from "react"

import Layout from "../components/Layout"
import errorbg from "../assets/img/error-bg.jpg"
import Header from "../components/Header"
import Nav from "../components/Nav"
import { OutboundLink } from "gatsby-plugin-gtag"

const ErrorPage = () => (
  <Layout>
    <div
      id="preloader"
      className="animated fadeOut"
      style={{
        backgroundColor: "#000",
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        zIndex: 2000,
      }}
    >
      <div class="pulse"></div>
    </div>
    <div class="border-top"></div>
    <div class="border-bottom"></div>
    <div class="border-left"></div>
    <div class="border-right"></div>
    <Header />
    <Nav />
    <div id="body-content">
      <section
        id="section-intro"
        class="intro-error full-height no-padding bg-image"
        style={{ backgroundImage: `url(${errorbg})` }}
      >
        <div class="cover"></div>

        <div class="intro-caption text-white">
          <div class="heading heading-lg">
            <h1>
              <span class="bg-main text-white">404</span> error
            </h1>
          </div>

          <p>Oops! Something went wrong.</p>
        </div>

        <div class="made-with-love hidden-xs">
          <OutboundLink href="http://intesso.com" target="blank">
            <p className="text-white">
              made with{" "}
              <span className="text-red">
                <i className="far fa-heart" />
              </span>
            </p>
          </OutboundLink>
        </div>
      </section>
    </div>
  </Layout>
)

export default ErrorPage
