import React from "react"
import config from "../../config"
import { Link } from "gatsby"

function Header(props) {
  return (
    <header
      id="header"
      className={
        props.isMenuOpen ? "lateral-menu-is-open" : "lateral-menu-is-closed"
      }
    >
      <Link id="cd-logo" to="/">
        <span className="bg-main text-white custom-logo">
          {config.siteTitle}
        </span>
      </Link>
      <button
        id="cd-menu-trigger"
        className={props.isMenuOpen ? "is-clicked" : "is-not-clicked"}
        onClick={props.hamburgerClicked}
      >
        <span className="cd-menu-icon"></span>
      </button>
    </header>
  )
}

export default Header
